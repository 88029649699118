import React, { useState } from "react";
import {
  RiAuctionLine,
  RiDashboardHorizontalLine,
  RiAdvertisementFill,
} from "react-icons/ri";
import { FaHospitalUser } from "react-icons/fa";
import { MdOutlineKeyboardArrowRight, MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { LuBeaker } from "react-icons/lu";
import { RiAdminFill } from "react-icons/ri";
const menuItems = [
  {
    key: "dashboard",
    label: "Dashboard",
    icon: <RiDashboardHorizontalLine size={26} />,
    link: "/dashboard",
  },
  {
    key: "userManagement",
    label: "User Management",
    icon: <FaHospitalUser size={26} />,
    subItems: [{ label: "All Users", link: "/userManagement" }],
  },
  {
    key: "commodity",
    label: "Commodity",
    icon: <RiAuctionLine size={26} />,
    subItems: [
      { label: "Add Commodity Type", link: "/addCommodity" },
      { label: "All Commodity Types", link: "/commodityList" },
      { label: "Add Commodity Name", link: "/addCommodityName" },
      { label: "All Commodity Name", link: "/commodityTypeList" },
    ],
  },
   
   
  {
    key: "bidding",
    label: "Bidding",
    icon: <RiAuctionLine size={26} />,
    subItems: [
      { label: "All Bidding", link: "/allBidding" },
      { label: "Highest Bidding", link: "/highestBidding" },
    ],
  },
  {
    key: "analytics",
    label: "Analytics",
    icon: <FaHospitalUser size={26} />,
    subItems: [
      { label: "Sales Analytics", link: "/salesAnalytics" },
      { label: "User Analytics", link: "/userAnalytics" },
    ],
  },
  {
    key: "banner",
    label: "Banner",
    icon: <RiAdvertisementFill size={26} />,
   
    subItems: [
      { label: "Add Banner", link: "/banner" },
      { label: "Show Banner", link: "/showBanner" },
      
    ],
  },
  {
    key: "auction",
    label: "Auction",
    icon: <LuBeaker className="h-6 w-6" />,
    subItems: [
      { label: "All New Auction", link: "/auction" },
      { label: "Approved Auction", link: "/approvalAuction" },
      { label: "Rejected Auction", link: "/rejectedAuction" },
      { label: "Listed Auction", link: "/listedAuction" },
    ],
  },
  {
    key: "Roles & Permissions",
    label: " Roles & Permissionson",
    icon:  <RiAdminFill className="h-6 w-6 " />,
    subItems: [
      { label: "Sub Admin Invite ", link: "/inviteSubAdmin" },
      { label: "Sub Admin", link: "/subAdmin" },
     
    ],
  },
];

function Sidebar({ isSidebarOpen,setIsSidebarOpen, toggleSidebar }) {
  const [activeLink, setActiveLink] = useState("");
  const [dropdowns, setDropdowns] = useState({});

  const handleLinkClick = (key, link) => {
    setActiveLink(key);
    setDropdowns((prev) => ({ ...prev, [key]: !prev[key] }));

    if (link && window.innerWidth < 1024) {
      toggleSidebar();
    }
  };
  

  return (
    <div
      className={`absolute lg:static lg:block min-h-screen  bg-[#3F5B4D] shadow-lg transition-transform duration-300 ease-in-out z-50
        ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} 
        lg:translate-x-0 w-[300px] lg:w-64`}
    >
      <div className="h-full overflow-y-auto">
        <div className="flex justify-end p-2 lg:hidden">
          <button onClick={toggleSidebar} className="text-white">
            <MdClose size={24} />
          </button>
        </div>

        <ul className="space-y-2 ml-6 py-4  max">
          {menuItems.map((item) => (
            <li key={item.key}>
              <div
                className={`flex items-center gap-4 cursor-pointer text-white
                  ${activeLink === item.key ? "bg-[#A7BFAF]" : "hover:text-[#A7BFAF]"}`}
                style={{ padding: "11px", borderRadius: "0px 47px 253px 0px" }}
                onClick={() => handleLinkClick(item.key, item.link)}
              >
                {item.icon}
                <div>
                  {item.link ? (
                    <Link to={item.link} className="text-sm">
                      {item.label}
                    </Link>
                  ) : (
                    <p className="text-sm">{item.label}</p>
                  )}
                </div>

                {item.subItems && (
                  <MdOutlineKeyboardArrowRight
                    size={32}
                    className={`transition-transform ml-auto
                      ${dropdowns[item.key] ? "rotate-90" : ""}`}
                  />
                )}
              </div>

              {dropdowns[item.key] && item.subItems && (
                <ul className="pl-8 space-y-2 text-white">
                  {item.subItems.map((subItem,index) => (
                    <li
                      key={subItem.label}
                      className="cursor-pointer hover:text-[#A7BFAF] py-2"
                    >
                      <Link to={subItem.link} className="text-sm" onClick={()=>setIsSidebarOpen(false)}>
                        {subItem.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;