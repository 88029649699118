import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../../BaseUrl';
import Template from '../Template';

const RejectedAuction = () => {
  const [rejectedAuctions, setRejectedAuctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRejectedAuctions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('Authentication token is missing');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${baseUrl}/admin/getAllRejectedAuctions`, {
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        });

        setRejectedAuctions(response.data.data || []); 
      } catch (err) {
        console.error('Error fetching data:', err);
     
      } finally {
        setLoading(false);
      }
    };

    fetchRejectedAuctions();
  }, []);

  if (loading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">{error}</div>;
  }

  return (
    <Template>
      <div className="p-6 bg-green-50">
        <h1 className="text-2xl font-bold mb-4">Rejected Auctions</h1>
        {rejectedAuctions.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-100 border-b">
                  <th className="py-2 px-4 border">Auction ID</th>
                  <th className="py-2 px-4 border">Commodity</th>
                  <th className="py-2 px-4 border">Quantity</th>
                  <th className="py-2 px-4 border">Base Price</th>
                  <th className="py-2 px-4 border">Grade</th>
                  <th className="py-2 px-4 border">Reason</th>
                  <th className="py-2 px-4 border">Rejected At</th>
                </tr>
              </thead>
              <tbody>
                {rejectedAuctions.map((auction) => (
                  <tr key={auction.customer_auction_id} className="border-b">
                    <td className="py-2 px-4 border">{auction.customer_auction_id}</td>
                    <td className="py-2 px-4 border">{auction.commodity_name}</td>
                    <td className="py-2 px-4 border">{auction.quantity} {auction.quantity_unit}</td>
                    <td className="py-2 px-4 border">{auction.base_price} {auction.currency}/{auction.base_price_unit}</td>
                    <td className="py-2 px-4 border">{auction.grade}</td>
                    <td className="py-2 px-4 border">{auction.approvalOrRejectionReason || 'N/A'}</td>
                    <td className="py-2 px-4 border">{auction.approvedOrRejectedAt ? new Date(auction.approvedOrRejectedAt).toLocaleString() : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center py-10">No rejected auctions available.</div>
        )}
      </div>
    </Template>
  );
};

export default RejectedAuction;
