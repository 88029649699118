import React, { useState, useEffect } from "react";
import Template from "./Template";
import { baseUrl } from "../../BaseUrl";
import { useNavigate } from "react-router-dom";
 
function AddCommodityName() {
  const [commodityName, setCommodityName] = useState("");
  const [commodityLocalName, setCommodityLocalName] = useState("");
  const [commodityTypes, setCommodityTypes] = useState([]);
  const [selectedCommodityTypeId, setSelectedCommodityTypeId] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // Fetch commodity types on component mount
  useEffect(() => {
    const fetchCommodityTypes = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        alert("Token not found. Please log in.");
        return;
      }

      try {
        const response = await fetch(`${baseUrl}/admin/getCommodityType`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const data = await response.json();
          setCommodityTypes(data.data);
        } else {
          const errorData = await response.json();
          alert(
            `Failed to fetch commodity types: ${
              errorData.message || "Unknown error"
            }`
          );
        }
      } catch (error) {
        alert(`Error: ${error.message}`);
      }
    };

    fetchCommodityTypes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    const token = localStorage.getItem("authToken");
    if (!token) {
      alert("Token not found. Please log in.");
      setLoading(false);
      return;
    }

    if (!selectedCommodityTypeId) {
      alert("Please select a commodity type.");
      setLoading(false);
      return;
    }

    const payload = {
      commodity_name: commodityName,
      commodity_local_name: commodityLocalName,
    };

    try {
      const response = await fetch(
        `${baseUrl}/admin/addCommodityName/${selectedCommodityTypeId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.status === 200 || response.status === 201) {
      
        setMessage("Commodity name added successfully!");
        setCommodityName("");
        setCommodityLocalName("");
        setSelectedCommodityTypeId("");
        setTimeout(() => {
          setMessage("");
          navigate("/commodityTypeList");
        }, 2000);
      } else {
        const errorData = await response.json();
        alert(
          `Failed to add commodity name: ${
            errorData.message || "Unknown error"
          }`
        );
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Template>
      <div className="flex justify-center items-start min-h-screen bg-green-50 pt-10">
        <div className="w-[80%] bg-white shadow-lg rounded-lg p-4 flex flex-col">
          {message && (
            <div
              className={`p-2 mb-4 rounded text-center font-semibold ${
                message.startsWith("Commodity name added")
                  ? "bg-green-100 text-[#26643d]"
                  : "bg-red-100 text-red-600"
              }`}
            >
              {message}
            </div>
          )}
          <h2 className="text-xl mb-4 text-[#4c4e52]">Add Commodity Name</h2>
          <hr className="border border-gray-300 mb-10" />
          <form onSubmit={handleSubmit}>
            <div className="mb-4 flex space-x-4">
              <div className="flex-1">
                <label
                  htmlFor="commodityType"
                  className="block text-lg font-medium text-[#4c4e52] mb-1"
                >
                  Commodity Type
                </label>
                <select
                  id="commodityType"
                  value={selectedCommodityTypeId}
                  onChange={(e) => setSelectedCommodityTypeId(e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-4 focus:ring-2 focus:ring-green-400 focus:outline-none"
                  required
                >
                  <option value="">Select Commodity Type</option>
                  {commodityTypes.map((commodityType) => (
                    <option
                      key={commodityType.commodity_type_id}
                      value={commodityType.commodity_type_id}
                    >
                      {commodityType.commodity_type_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex-1">
                <label
                  htmlFor="commodityName"
                  className="block text-lg font-medium text-[#4c4e52] mb-1"
                >
                  Commodity Name
                </label>
                <input
                  type="text"
                  id="commodityName"
                  value={commodityName}
                  onChange={(e) => setCommodityName(e.target.value)}
                  placeholder="Enter commodity name"
                  className="w-full border border-gray-300 rounded-md p-4 focus:ring-2 focus:ring-green-400 focus:outline-none"
                  required
                />
              </div>
            </div>

            <div className="mb-4">
              <label
                htmlFor="commodityLocalName"
                className="block text-lg font-medium text-[#4c4e52] mb-1"
              >
                Commodity Local Name
              </label>
              <input
                type="text"
                id="commodityLocalName"
                value={commodityLocalName}
                onChange={(e) => setCommodityLocalName(e.target.value)}
                placeholder="Enter commodity local name"
                className="w-full border border-gray-300 rounded-md p-4 focus:ring-2 focus:ring-green-400 focus:outline-none"
                required
              />
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-[#255b39] text-white font-extrabold px-6 py-2 rounded-md hover:bg-green-600"
                disabled={loading}
              >
                {loading ? "Saving..." : "Save Commodity"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Template>
  );
}

export default AddCommodityName;
