import React, { useState } from "react";
import axios from "axios";
import Template from "../Template";
import { baseUrl } from "../../../BaseUrl";

const Banner = () => {
  const [bannerData, setBannerData] = useState({
    title: "",
    description: "",
    image: null,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setBannerData((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBannerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const token = localStorage.getItem("authToken");

    try {
      const response = await axios.post(
        `${baseUrl}/admin/addBannersOnHomePage`,
        bannerData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message === "Banner created successfully") {
     
        alert("Banner created successfully");
      }
      setBannerData({ title: "", description: "", image: null });
    } catch (error) {
      console.error("Error posting banner:", error);
      alert("Error posting banner. Please try again.");
    }
  };

  return (
    <Template>
      <div className="max-w-2xl mx-auto mt-10 p-3 py-10 bg-green-50  ">
        <div className="p-6 bg-white shadow-lg rounded-lg">
          <h1 className="text-2xl font-bold mb-6 text-center">Create Banner</h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block mb-2 text-sm font-medium">
                Upload Banner Image
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="block w-full text-sm border rounded-md"
              />
              {bannerData.image && (
                <img
                  src={URL.createObjectURL(bannerData.image)}
                  alt="Banner Preview"
                  className="mt-4 w-full h-48 object-cover rounded-md"
                />
              )}
            </div>

            <div>
              <label className="block mb-2 text-sm font-medium">Title</label>
              <input
                type="text"
                name="title"
                value={bannerData.title}
                onChange={handleInputChange}
                className="block w-full p-2 border rounded-md"
                placeholder="Enter Banner Title"
                required
              />
            </div>

            <div>
              <label className="block mb-2 text-sm font-medium">
                Description
              </label>
              <textarea
                name="description"
                value={bannerData.description}
                onChange={handleInputChange}
                className="block w-full p-2 border rounded-md"
                placeholder="Enter Banner Description"
                rows="4"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
            >
              Submit Banner
            </button>
          </form>
        </div>
      </div>
    </Template>
  );
};

export default Banner;
