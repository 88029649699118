import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import Template from '../Template';

// Registering the necessary chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const UserAnalytics = () => {
  // Sample data for the line chart
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'], // months
    datasets: [
      {
        label: 'Active Users', // label for the graph
        data: [120, 200, 180, 240, 300, 500], // active users count per month
        borderColor: '#34D399', // line color
        backgroundColor: 'rgba(52, 211, 153, 0.2)', // line background color (for filling)
        borderWidth: 2,
        tension: 0.4, // smooth curve
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
  };

  return (
    <Template>
         <div className="min-h-screen bg-green-50 p-8">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-center mb-6">User Analytics</h2>
        <div className="w-full max-w-4xl mx-auto">
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
    </Template>
  );
};

export default UserAnalytics;
