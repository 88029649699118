import React, { useState } from 'react';
import Template from './Template';
import { baseUrl } from '../../BaseUrl';
import { useNavigate } from 'react-router-dom';

function AddCommodity() {
  const [commodityTypeName, setCommodityTypeName] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(''); 
  const navigate = useNavigate();



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    const token = localStorage.getItem('authToken');
    if (!token) {
      alert('Token not found. Please log in.');
      setLoading(false);
      return;
    }

    const payload = {
      commodity_type_name: commodityTypeName,
    };

    try {
      const response = await fetch(`${baseUrl}/admin/addCommodityType`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "ngrok-skip-browser-warning": "69420",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.status===200 ||response.status===201) {
      
        setMessage('Commodity added successfully!');
        setCommodityTypeName(''); 
        setTimeout(() => {
          setMessage('');
          navigate('/commity');
        }, 2000);
        
      } else {
        const errorData = await response.json();
        alert(`Failed to add commodity: ${errorData.message || 'Unknown error'}`);
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Template>
      <div className="flex justify-center items-start min-h-screen bg-green-50 pt-10">
        <div className="w-[80%] bg-white shadow-lg rounded-lg p-4 flex flex-col">
        {message && (
            <div
              className={`p-2 mb-4 rounded text-center font-semibold ${
                message.startsWith('Commodity added')
                  ? 'bg-green-100 text-green-600'
                  : 'bg-red-100 text-red-600'
              }`}
            >
              {message}
            </div>
          )}
          <h2 className="text-xl mb-4 text-[#56585d">Add Commodity Type </h2>
          <hr className="border border-gray-300 mb-10" />
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="commodityTypeName"
                className="block text-lg font-medium text-[#56585d] mb-1"
              >
                Commodity Type
              </label>
              <input
                type="text"
                id="commodityTypeName"
                value={commodityTypeName}
                onChange={(e) => setCommodityTypeName(e.target.value)}
                placeholder="Enter commodity type "
                className="w-full border border-gray-300 rounded-md p-4 focus:ring-2 focus:ring-[#29653f] focus:outline-none"
                required
              />
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-[#29653f] text-white font-extrabold px-6 py-2 rounded-md hover:bg-[#26643d]"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Commodity'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Template>
  );
}

export default AddCommodity;
