import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../../BaseUrl';
import Template from '../Template';

const AllBidding = ({list_auction_id}) => {
 console.log()
  const [bids, setBids] = useState([]);  
  const [listAuction, setListAuction] = useState(null); 
  const [loading, setLoading] = useState(true);  

  useEffect(() => {
    const fetchBids = async () => {
      const token = localStorage.getItem('authToken');  

      if (!token) {
        console.error('No token found');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${baseUrl}/admin/getAllBids/${list_auction_id}`, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.data) {
          const { bids, listAuction } = response.data.data;
          setBids(bids || []);
          setListAuction(listAuction || null);
        }
      } catch (error) {
        console.error('Error fetching bids:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBids();
  }, [list_auction_id]);

  return (
    <Template>
      <div className="container mx-auto p-4 bg-green-50">
        <h1 className="text-2xl font-bold mb-4">All Bids</h1>
        
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {listAuction ? (
              <div className="border p-4 rounded-md shadow mb-6 bg-white">
                <h2 className="text-xl font-semibold mb-2">Auction Details</h2>
                <p><strong>ID:</strong> {listAuction.listAuction_id}</p>
                <p><strong>Commodity Name:</strong> {listAuction.customerAuction.commodity_name}</p>
                <p><strong>Quantity:</strong> {listAuction.customerAuction.quantity} kg</p>
                <p><strong>Base Price:</strong> ${listAuction.customerAuction.base_price}</p>
                <p><strong>Start Time:</strong> {new Date(listAuction.time_slot_start).toLocaleString()}</p>
                <p><strong>End Time:</strong> {new Date(listAuction.time_slot_end).toLocaleString()}</p>
                <p><strong>Live:</strong> {listAuction.isLive ? 'Yes' : 'No'}</p>
                <p><strong>Completed:</strong> {listAuction.isCompleted ? 'Yes' : 'No'}</p>
              </div>
            ) : (
              <p>No auction details available.</p>
            )}

            {bids.length > 0 ? (
              <ul className="space-y-4">
                {bids.map((bid) => (
                  <li key={bid.id} className="border p-4 rounded-md shadow bg-white">
                    <p><strong>Bid ID:</strong> {bid.id}</p>
                    <p><strong>Amount:</strong> ${bid.amount}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No bids available.</p>
            )}
          </>
        )}
      </div>
    </Template>
  );
};

export default AllBidding;
