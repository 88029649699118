import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../../BaseUrl';
import Template from '../Template';

const HighestBids = ({list_auction_id}) => {
  const [highestBid, setHighestBid] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHighestBid = async () => {
        const authToken = localStorage.getItem('authToken');
      try {
        const response = await axios.get(`${baseUrl}/admin/getHighestBid/${list_auction_id}`,{
            headers: {
              "ngrok-skip-browser-warning": "69420",
              Authorization: `Bearer ${authToken}`,
            },
          });
        setHighestBid(response.data.data);  
      } catch (err) {
        setError('Failed to fetch the highest bid.');
      }
    };

    fetchHighestBid();
  }, [list_auction_id]);

  return (
     <Template>

<div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Highest Bid Details</h1>
      {error && <p className="text-red-500">{error}</p>}
      {highestBid ? (
        <div className="border rounded-lg p-4 bg-gray-50">
          <h2 className="text-lg font-semibold mb-2">Auction Details</h2>
          <p><strong>Auction ID:</strong> {highestBid.list_auction_id}</p>
          <p><strong>Customer Name:</strong> {`${highestBid.customer.first_name} ${highestBid.customer.last_name}`}</p>
          <p><strong>Email:</strong> {highestBid.customer.email}</p>
          <p><strong>Phone Number:</strong> {highestBid.customer.phoneNumber}</p>
          <p><strong>Highest Bid Amount:</strong> ${highestBid.bid_amount}</p>
          <p><strong>Bid Status:</strong> {highestBid.status}</p>
          <p><strong>Is Live Auction:</strong> {highestBid.listAuction.isLive ? 'Yes' : 'No'}</p>
          <p><strong>Time Slot Start:</strong> {new Date(highestBid.listAuction.time_slot_start).toLocaleString()}</p>
          <p><strong>Time Slot End:</strong> {new Date(highestBid.listAuction.time_slot_end).toLocaleString()}</p>
        </div>
      ) : (
        <p>Loading highest bid details...</p>
      )}
    </div>
     </Template>
  );
};

export default HighestBids;
