import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Template from '../Template';
import { baseUrl } from '../../../BaseUrl';
import { Link } from 'react-router-dom';

const ListedAuction = () => {
  const [auctions, setAuctions] = useState([]);
  const [ , setBids] = useState([]);
  const [ , setShowBids] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAuctions = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('authToken');
      try {
        const response = await axios.get(`${baseUrl}/admin/getAllListedAuctions`, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        });
        setAuctions(response.data.data.auctions);
        setShowBids(response.data.data.commodities);
        setBids(response.data.data.commodities || []);
      } catch (error) {
        console.error('Error fetching auctions:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAuctions();
  }, []);

  

    

 

  return (
    <Template>
    <div className="overflow-x-auto p-6 bg-green-50">
      <div className="flex space-x-4 mb-4">
        <Link to={"/allBidding/:listAuction_id"}>
          <button className="px-4 py-2 bg-[#27633d] text-white rounded">All Bids</button>
        </Link>
        <Link to={"/highestBids/:listAuction_id"}>
          <button className="px-4 py-2 bg-[#36517c] text-white rounded">Highest Bids</button>
        </Link>
      </div>

      {isLoading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <table className="min-w-full table-auto border-collapse">
          <thead>
            <tr>
              <th className="px-4 py-2 border">ID</th>
              <th className="px-4 py-2 border">Customer Name</th>
              {/* <th className="px-4 py-2 border">Commodity Name</th> */}
              <th className="px-4 py-2 border">Time Slot </th>
           
              <th className="px-4 py-2 border">Status</th>
            </tr>
          </thead>
          <tbody>
          { auctions.map((auction) => (
                  <tr key={auction.listAuction_id}>
                    <td className="px-4 py-2 border">{auction.listAuction_id}</td>
                    <td className="px-4 py-2 border">
                      {auction.customerAuction.customer.first_name} {auction.customerAuction.customer.last_name}
                    </td>
                    {/* <td className="px-4 py-2 border">{auction .commodities.commodity_name}</td> */}
                    <td className="px-4 py-2 border">
                      {new Date(auction.time_slot_start).toLocaleString()} -{' '}
                      {new Date(auction.time_slot_end).toLocaleString()}
                    </td>
                   
                    <td className="px-4 py-2 border">
                      {auction.auctionStatus === 'upcoming' ? 'Upcoming' : auction.auctionStatus}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      )}
    </div>
  </Template>
  );
};

export default ListedAuction;
