// import React, { useState } from "react";

// const ThemeChanger = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const togglePopup = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className="relative">
//       {/* Button to open the theme popup */}
//       <button
//         onClick={togglePopup}
//         className="fixed bottom-5 right-5 bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600"
//       >
//         ⚙️
//       </button>

//       {/* Theme Popup */}
//       {isOpen && (
//         <div className="fixed top-0 right-0 w-96 h-screen bg-white shadow-lg p-6 z-50">
//           {/* Close Button */}
//           <button
//             onClick={togglePopup}
//             className="absolute top-3 right-3 text-gray-600 hover:text-red-600"
//           >
//             ✖
//           </button>

//           {/* Header */}
//           <h2 className="text-xl font-semibold mb-4">Pick your style</h2>

//           {/* Tabs */}
//           <div className="flex gap-4 mb-6">
//             <button className="text-green-600 border-b-2 border-green-600">
//               Theme
//             </button>
//             <button className="text-gray-600 hover:text-green-600">Header</button>
//             <button className="text-gray-600 hover:text-green-600">Content</button>
//             <button className="text-gray-600 hover:text-green-600">
//               Navigation
//             </button>
//           </div>

//           {/* Background */}
//           <div className="mb-4">
//             <label className="block text-gray-700 font-medium mb-2">Background</label>
//             <select
//               className="w-full border-gray-300 rounded-lg p-2"
//               defaultValue="Choose Mode"
//             >
//               <option value="Choose Mode" disabled>
//                 Choose Mode
//               </option>
//               <option value="Light">Light</option>
//               <option value="Dark">Dark</option>
//             </select>
//           </div>

//           {/* Color Options */}
//           <div className="mb-4">
//             <h3 className="text-lg font-medium mb-2">Primary Color</h3>
//             <div className="grid grid-cols-5 gap-2">
//               <div className="w-8 h-8 bg-red-500 rounded cursor-pointer"></div>
//               <div className="w-8 h-8 bg-blue-500 rounded cursor-pointer"></div>
//               <div className="w-8 h-8 bg-green-500 rounded cursor-pointer"></div>
//               <div className="w-8 h-8 bg-yellow-500 rounded cursor-pointer"></div>
//               <div className="w-8 h-8 bg-purple-500 rounded cursor-pointer"></div>
//             </div>
//           </div>

//           {/* Sidebar */}
//           <div className="mb-4">
//             <h3 className="text-lg font-medium mb-2">Sidebar</h3>
//             <div className="grid grid-cols-5 gap-2">
//               <div className="w-8 h-8 bg-gray-200 rounded cursor-pointer"></div>
//               <div className="w-8 h-8 bg-gray-500 rounded cursor-pointer"></div>
//               <div className="w-8 h-8 bg-gray-700 rounded cursor-pointer"></div>
//               <div className="w-8 h-8 bg-blue-500 rounded cursor-pointer"></div>
//               <div className="w-8 h-8 bg-red-500 rounded cursor-pointer"></div>
//             </div>
//           </div>

//           {/* Footer Note */}
//           <p className="text-xs text-gray-500 mt-6">
//             *Note: This theme switcher is not part of the product. It is only
//             for demo purposes.
//           </p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ThemeChanger;

import React, { useState, useEffect } from "react";
import { IoMdSettings } from "react-icons/io";


const ThemeChanger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [background, setBackground] = useState("Light");
  const [primaryColor, setPrimaryColor] = useState("green");
  const [sidebarColor, setSidebarColor] = useState("gray");

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (background === "Dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [background]);

  return (
    <div className="relative">
      <button
        onClick={togglePopup}
        className="fixed bottom-5 right-5 bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600"
      >
      <IoMdSettings size={24}/>
      </button>

      {isOpen && (
        <div className="fixed top-0 right-0 w-50 h-screen bg-white dark:bg-gray-200 shadow-lg p-6 z-50">
          <button
            onClick={togglePopup}
            className="absolute top-3 right-3 text-gray-600 dark:text-gray-300 hover:text-red-600"
          >
            ✖
          </button>

          <h2 className="text-xl font-semibold mb-4">Pick your style</h2>

          <div className="flex gap-4 mb-6">
            <button className="text-green-600 border-b-2 border-green-600">
              Theme
            </button>
            <button className="text-gray-600 hover:text-green-600">Header</button>
            <button className="text-gray-600 hover:text-green-600">Content</button>
            <button className="text-gray-600 hover:text-green-600">
              Navigation
            </button>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-300 font-medium mb-2">Background</label>
            <select
              onChange={(e) => setBackground(e.target.value)}
              value={background}
              className="w-full border-gray-300 dark:border-gray-700 rounded-lg p-2"
            >
              <option value="Light">Light</option>
              <option value="Dark">Dark</option>
            </select>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-medium mb-2">Primary Color</h3>
            <div className="grid grid-cols-5 gap-2">
              {["red", "blue", "green", "yellow", "purple"].map((color) => (
                <div
                  key={color}
                  className={`w-8 h-8 bg-${color}-500 rounded cursor-pointer ${primaryColor === color ? "border-4 border-black" : ""}`}
                  onClick={() => setPrimaryColor(color)}
                ></div>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-medium mb-2">Sidebar</h3>
            <div className="grid grid-cols-5 gap-2">
              {["gray", "blue", "red"].map((color) => (
                <div
                  key={color}
                  className={`w-8 h-8 bg-${color}-500 rounded cursor-pointer ${sidebarColor === color ? "border-4 border-black" : ""}`}
                  onClick={() => setSidebarColor(color)}
                ></div>
              ))}
            </div>
          </div>
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-6">
            *Note: This theme switcher is not part of the product. It is only for demo purposes.
          </p>
        </div>
      )}
    </div>
  );
};

export default ThemeChanger;
