import React, {   useState } from "react";
import axios from "axios";
import { baseUrl } from "../../BaseUrl";
import Template from "../Page/Template";

const AllFeatures = () => {
  const [features, ] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [ , setSuccessMessage] = useState("");
  const [ , setInvitationError] = useState("");
  const [loading,  ] = useState(true);
  const [error,  ] = useState(null);
 

 
  const token = localStorage.getItem("authToken");

 
  
  const handleSendInvitation = async () => {
    setInvitationError("");
    setSuccessMessage("");
    
    if (!email || !role || selectedFeatures.length === 0) {
      setInvitationError("All fields are required.");
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/admin/sendInvitationToSubAdmin`,
        {
          email,
          role: [Number(role)],
          featureIds: selectedFeatures,
        },
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if ([200, 201].includes(response.status)) {
        setSuccessMessage("Invitation sent successfully!");
        setEmail("");
        setRole("");
        setSelectedFeatures([]);
      } else {
        setInvitationError("Failed to send invitation.");
      }
    } catch (err) {
      setInvitationError("Error sending invitation.");
    }
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
     <Template>
     <div className="p-6 bg-gray-100 min-h-screen">
  <div className="max-w-2xl mx-auto bg-white p-8 rounded shadow-lg mt-10">
    <h1 className="text-2xl font-bold mb-6">Invite Sub-Admin</h1>
    <div className="mb-4">
      <label className="block mb-2">Email Address</label>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full p-3 border rounded"
        placeholder="Enter email"
      />
    </div>
    <div className="mb-4">
      <label className="block mb-2">Role</label>
      <select
        value={role}
        onChange={(e) => setRole(e.target.value)}
        className="w-full p-3 border rounded"
      >
        <option value="">Select Role</option>
        <option value="1">Sub-Admin</option>
        <option value="2">Manager</option>
        <option value="3">Editor</option>
      </select>
    </div>

    {/* Table container with responsive classes */}
    <div className="overflow-x-auto mb-4">
      <table className="min-w-full table-auto">
        <thead>
          <tr>
            <th className="px-4 py-2 border">Feature Name</th>
            <th className="px-4 py-2 border">Action</th>
          </tr>
        </thead>
        <tbody>
          {features.map((feature) => (
            <tr key={feature.id}>
              <td className="px-4 py-2 border">{feature.name}</td>
              <td className="px-4 py-2 border">
                <button className="text-blue-500">Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    {/* Rest of your form and buttons */}
    <button
      onClick={handleSendInvitation}
      className="w-full bg-green-600 text-white p-3 rounded"
    >
      Send Invitation
    </button>
  </div>
</div>

     </Template>
  );
};

export default AllFeatures;
