import React, { useState, useEffect } from "react";
import Template from "../Template";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import UpdateBanner from "./UpdateBanner";

const ShowBanner = () => {
  const [banners, setBanners] = useState([]);

  const fetchBanners = async () => {
    try {
      const response = await axios.get(`${baseUrl}/admin/getAllBanners`, {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
        },
      });
      setBanners(response.data.banners);
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <Template>
      <div className="max-w-6xl mx-auto mt-10 p-6 max-h-screen overflow-auto">
        <div className="p-6 bg-white shadow-lg rounded-lg      ">
          <h1 className="text-2xl font-bold mb-6 text-center">
            Banner Details
          </h1>
          {banners && banners.length > 0 ? (
            <div className="grid grid-cols-1   gap-6  ">
              {banners.map((banner) => (
                <div
                  key={banner.banner_id}
                  className="bg-gray-100 p-4 rounded-lg shadow-md"
                >
                  <div>
                    <img
                      src={banner.banner_image}
                      alt={banner.title}
                      className="w-full h-48 object-cover rounded-md mb-4"
                    />
                    <h2 className="text-xl font-semibold mb-2">
                      {banner.title}
                    </h2>
                    <p className="text-sm text-gray-500">
                      {banner.description}
                    </p>
                  </div>
                  <div>
                    <UpdateBanner
                      banner_id={banner.banner_id}
                     
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500">
              No banner data available
            </p>
          )}
        </div>
      </div>
    </Template>
  );
};

export default ShowBanner;
