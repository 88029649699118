import React, { useState, useRef, useEffect } from "react";
import logo from "../assets/Logo.png";
 
import profile from "../assets/profile.jpeg";
import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
 
import { IoIosLogOut, IoIosNotifications, IoIosClose } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";

function Navbar({ toggleSidebar }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const profileRef = useRef(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const handleNavigateToLogout = () => {
    localStorage.removeItem("token");
    navigate("/LoginPage");
    setIsMenuOpen(false);
  };

  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileRef.current &&
        !profileRef.current.contains(event.target) &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto">
        <div className="flex justify-between items-center py-4 px-4">
          <div className="flex items-center gap-4">
            <img className="w-[60px]" src={logo} alt="Logo" />
          </div>

          <div className="flex items-center gap-6">
            <IoIosNotifications className="text-2xl text-gray-600 cursor-pointer" />

            <div className="relative" ref={profileRef}>
              <div
                className="flex items-center gap-3 w-40 border-2 rounded-xl bg-slate-50 pr-4 cursor-pointer hover:bg-slate-100"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <img
                  src={profile}
                  alt="profile"
                  className="w-12 h-12 rounded-full border-2 border-white"
                />
                <h1 className="text-[#1f6037] font-bold text-sm">
                  Hello, Cool Admin
                </h1>
              </div>

              {isMenuOpen && (
                <div
                  ref={menuRef}
                  className="absolute right-0 mt-2 w-48 bg-gray-100 shadow-lg rounded-md py-2 border z-50"
                >
                  <ul className="flex flex-col">
                    <li className="px-4 py-2 hover:bg-blue-100 cursor-pointer text-black">
                      <Link to="/myProfile">
                        <CgProfile className="inline-block mr-2 text-lg" />{" "}
                        Super Admin
                      </Link>
                    </li>
                    {/* <li className="px-4 py-2 hover:bg-blue-100 cursor-pointer text-black">
                      <Link to="/inviteSubAdmin">
                        <RiAdminFill className="inline-block mr-2 text-lg" />
                        Sub Admin Invite
                      </Link>
                    </li>
                    <li className="px-4 py-2 hover:bg-blue-100 cursor-pointer text-black">
                      <Link to="/subAdmin">
                        <FaLock className="inline-block mr-2 text-lg" /> Sub
                        Admin
                      </Link>
                    </li> */}
                    <li
                      onClick={handleNavigateToLogout}
                      className="px-4 py-2 hover:bg-blue-100 cursor-pointer text-black"
                    >
                      <IoIosLogOut className="inline-block mr-2 text-lg" />{" "}
                      Logout
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <button className="lg:hidden text-gray-600 hover:text-gray-900">
              {isMenuOpen ? (
                <button className="lg:hidden ml-4" onClick={toggleSidebar}>
                  <IoIosClose size={24} />
                </button>
              ) : (
                <button className="lg:hidden ml-4" onClick={toggleSidebar}>
                  <GiHamburgerMenu size={24} />
                </button>
              )}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
