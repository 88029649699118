import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../BaseUrl';
import Template from './Page/Template';

const AcceptInvite = () => {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [status, setStatus] = useState('');
  const navigate = useNavigate();

  const token = searchParams.get('authToken');

  useEffect(() => {
    if (!token) {
      setStatus('Invalid or missing invitation token.');
    }
  }, [token]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const acceptInvitation = async (inviteToken) => {
    if (password !== confirmPassword) {
      setStatus('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/admin/createPasswordSubAdmin`,
        { token: inviteToken, password }
      );
      if (response.status === 200) {
        setStatus('Password set successfully! Redirecting...');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
    } catch (err) {
      setStatus(
        err.response?.data?.message || 'Failed to set password. Please try again.'
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (token) {
      acceptInvitation(token);
    } else {
      setStatus('Invalid or expired invitation link.');
    }
  };

  return (
    <Template>
       <div className="max-w-lg mx-auto mt-20 p-6 border rounded-lg shadow-md bg-white text-center">
      <h2 className="text-2xl font-bold mb-4">Set Your Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-left font-medium mb-2">New Password</label>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            className="w-full p-2 border rounded-lg"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-left font-medium mb-2">Confirm Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            className="w-full p-2 border rounded-lg"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700"
        >
          Save Password
        </button>
      </form>
      {status && (
        <p
          className={`mt-4 ${status.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}
        >
          {status}
        </p>
      )}
    </div>




    
    </Template>
  );
};

export default AcceptInvite;
