import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import Template from "../Template";
 
import ApprovalRejected from "./ApprovalRejected";

const Auction = () => {
  const [auctions, setAuctions] = useState([]);
  const [ , setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    setToken(authToken);

    if (authToken) {
      fetchAuctions(authToken);
    } else {
      setLoading(false);
      setError("Authentication token not found. Please log in.");
    }
  }, []);

  const fetchAuctions = async (authToken) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${baseUrl}/admin/getAllNewAuctionsForApproval`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && Array.isArray(response.data.data)) {
        setAuctions(response.data.data);
        console.log(response.data.data, "Auction Data");
      } else {
        setAuctions([]);
        setError("No auctions available for approval.");
      }
    } catch (error) {
      console.error("Error fetching auctions:", error);
      setError("Failed to fetch auctions. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Template>
      <div className="bg-green-50">
        <div className="container mx-auto h-screen p-4 ">
          <h1 className="text-2xl font-semibold mb-4">
            New Auctions For Approval
          </h1>

          {loading ? (
            <p className="text-center">Loading...</p>
          ) : error ? (
            <p className="text-center text-[#784545">{error}</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="py-2 px-4 border">Item</th>
                    <th className="py-2 px-4 border">Quantity</th>
                    <th className="py-2 px-4 border">Base Price</th>
                    <th className="py-2 px-4 border">Grade</th>
                    <th className="py-2 px-4 border">Quality Type</th>
                    <th className="py-2 px-4 border">Video</th>
                    <th className="py-2 px-4 border">Images</th>
                    <th className="py-2 px-4 border">Approved/Rejected</th>
                  </tr>
                </thead>
                <tbody>
                  {auctions.map((auction, index) => (
                    <tr key={index} className="text-center border">
                      <td className="py-2 px-4 border">
                        {auction.commodity_name}
                      </td>
                      <td className="py-2 px-4 border">
                        {auction.quantity} {auction.quantity_unit}
                      </td>
                      <td className="py-2 px-4 border">
                        {auction.base_price} {auction.base_price_unit}
                      </td>
                      <td className="py-2 px-4 border">{auction.grade}</td>
                      <td className="py-2 px-4 border">
                        {auction.quality_type}
                      </td>
                      <td className="py-2 px-4 border">
                        {auction.video_url ? (
                          <a
                            href={auction.video_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                          >
                            Watch Video
                          </a>
                        ) : (
                          "No Video"
                        )}
                      </td>
                      <td className="py-2 px-4 border">
                        {auction.images && auction.images.length > 0 ? (
                          <div className="flex gap-2 justify-center">
                            {auction.images.map((img, idx) => (
                              <img
                                key={idx}
                                src={img}
                                alt="Auction"
                                className="w-20 h-20 object-cover"
                              />
                            ))}
                          </div>
                        ) : (
                          "No Images"
                        )}
                      </td>
                      <td className="">
                        <ApprovalRejected
                          customer_auction_id={auction.customer_auction_id}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </Template>
  );
};

export default Auction;
