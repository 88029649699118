import React, { useEffect, useState } from "react";
 
import { baseUrl } from "../../BaseUrl";
import person from "../../assets/profile.jpeg";
import Template from "../Page/Template";

const SubAdminProfile = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await fetch(
        `${baseUrl}/admin/getAllAdminDetails`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        setUsers(data.admins || []);  
      } else {
        alert(data.message || "Failed to fetch users");
      }
    } catch (error) {
      alert("An error occurred while fetching users");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getRoleName = (role) => {
    if (role.includes(1)) return "SubAdmin";
    if (role.includes(2)) return "Manager";
    if (role.includes(3)) return "Editor";
    return "Unknown";
  };

  return (
     <Template>
      <div className="justify-center items-center min-h-screen h-full px-4  mx-auto">
     
      <h2 className="text-2xl font-semibold text-center my-6">
        Sub Admin, Manager, and Editor
      </h2>
      {loading ? (
        <p className="text-center text-gray-600">Loading users...</p>
      ) : (
        <div className="max-w-4xl mx-auto bg-white   rounded-lg shadow-lg overflow-x-auto">
          {users.length > 0 ? (
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border p-3">S.No</th>
                  <th className="border p-3">Profile</th>
                  <th className="border p-3">Email (ID)</th>
                  <th className="border p-3">Role</th>
                  <th className="border p-3">Features</th>
                  <th className="border p-3">Invitation</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={user.admin_id} className="text-center hover:bg-gray-100">
                    <td className="border p-3">{index + 1}</td>
                    <td className="border p-3">
                      <img
                        src={user.profileImage || person}
                        alt="Profile"
                        className="h-16 w-16 rounded-full mx-auto object-cover"
                      />
                    </td>
                    <td className="border p-3">
                      {user.email} <br /> (ID: {user.admin_id})
                    </td>
                    <td className="border p-3">{getRoleName(user.role)}</td>
                    <td className="border p-3">
                      {user.assignedFeatures.join(", ")}
                    </td>
                    <td className="border p-3">
                      {user.isInvitationAccepted ? "Accepted" : "Pending"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center text-gray-600">No users found</p>
          )}
        </div>
      )}
    </div>
     </Template>
  );
};

export default SubAdminProfile;
