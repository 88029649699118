import React, { useState } from "react";
import axios from "axios"; 
import Logo from '../assets/Logo.png';
import ThemeChanger from "./ThemeChanger";
import { baseUrl } from "../BaseUrl";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate =useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        try {
            const response = await axios.post(`${baseUrl}/admin/loginAdmin`, {
                email,
                password,
            });

            console.log("API Response:", response.data);

            if (response.status===200 || response.status===201) {
                const token = response.data.token; 
                localStorage.setItem("authToken", token); 
                alert("Login successful!");
               navigate("/"); 
            } else {
                setError(response.data.message || "Login failed. Please try again.");
            }
        } catch (err) {
            console.error("API Error:", err);
            setError("An error occurred while logging in. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
                <ThemeChanger />
                <img
                    src={Logo}
                    alt="Logo"
                    className="mx-auto mb-4 w-32 h-auto"
                />
                <h2 className="text-xl font-semibold text-center text-green-500 mb-4">
                    Sign in your account
                </h2>
                {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                            required
                        />
                    </div>
                    <div className="mb-4 relative">
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700 mb-1"
                        >
                            Password
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                            required
                        />
                        <span
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-3 top-9 cursor-pointer text-gray-600"
                        >
                            {showPassword ? "🙈" : "👁️"}
                        </span>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                        <Link 
                            to={'/forgetPassword'}
                            className="text-sm text-blue-500 hover:underline hover:text-green-500"
                        >
                            Forgot Password?
                        </Link >
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg font-semibold"
                    >
                        {loading ? "Signing In..." : "Sign In"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
