import React from "react";
import Template from "./Template";
import { SlCalender } from "react-icons/sl";


function Dashboard() {
  const cards = [
    { name: "Commodity", count: 150, color: "bg-red-500" },
    { name: "Industry", count: 195, color: "bg-green-500" },
    { name: "Startup Insights", count: 34, color: "bg-blue-400" },
    { name: "CXOs", count: 24, color: "bg-purple-500" },
    { name: "Leader Speaks", count: 16, color: "bg-yellow-400" },
    { name: "Latest News", count: 206, color: "bg-blue-700" },
    { name: "Magazine", count: 23, color: "bg-green-400" },
    { name: "Cover Story", count: 23, color: "bg-gray-600" },
    { name: "Featured People", count: 332, color: "bg-blue-600" },
    { name: "Video", count: 5, color: "bg-orange-500" },
    { name: "News/Blogs", count: 95, color: "bg-purple-400" },
    { name: "Banner", count: 3, color: "bg-indigo-500" },
  ];

  return (
    <Template>
    <div className="min-h-screen flex bg-green-50">
      <main className="flex-1 p-6">
        <header className="flex justify-between items-center mb-6">
          <h1 className="text-4xl font-bold text-black">
            Welcome to Cool Admin!
          </h1>
        </header>
        <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 z-10 gap-8">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`relative p-8  rounded-lg shadow-lg text-white overflow-hidden ${card.color}`}
          >
                        <div className="absolute inset-0 bg-white/20 pointer-events-none"></div>

            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-xl">{card.name}</h3>
                <p className="text-3xl ">{card.count}</p>
              </div>
              <div className="border-2 border-white/75 rounded-full w-14 h-14 flex items-center justify-center">
                <span className="text-white/75 text-xl">
                  <SlCalender />
                </span>
              </div>
            </div>
          </div>
        ))}
           </div>
           </div>
      </main>
    </div>
    </Template>
  );
}

export default Dashboard;
