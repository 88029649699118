import React from "react";
import Template from "../Template";

const BiddingList = () => {
  return (
    <Template>
      <div className="overflow-x-auto bg-green-50">
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2">
                Drop Down Value
              </th>
              <th className="border border-gray-300 px-4 py-2">Quantity</th>
              <th className="border border-gray-300 px-4 py-2">
                Quantity Unit
              </th>
              <th className="border border-gray-300 px-4 py-2">Base Price</th>
              <th className="border border-gray-300 px-4 py-2">
                Base Price Unit
              </th>
              <th className="border border-gray-300 px-4 py-2">Currency</th>
              <th className="border border-gray-300 px-4 py-2">Grade</th>
              <th className="border border-gray-300 px-4 py-2">Quality Type</th>
              <th className="border border-gray-300 px-4 py-2">Video URL</th>
            </tr>
          </thead>
        </table>
      </div>
    </Template>
  );
};

export default BiddingList;
