import React, { useState } from "react";
import Navbar from "../Navbar";
import Sidebar from '../Sidebar';

const Template = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    
    setIsSidebarOpen(!isSidebarOpen);
    console.log(isSidebarOpen)
  };

  return (
    <div className="flex min-h-screen  ">
      <Sidebar isSidebarOpen={isSidebarOpen}  toggleSidebar={toggleSidebar} setIsSidebarOpen={setIsSidebarOpen}/>
      
      <div className="flex flex-col flex-1 ">
        <Navbar toggleSidebar={toggleSidebar} />
        <main className="flex-1  overflow-y-auto">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Template;