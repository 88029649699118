import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import ThemeChanger from "./components/ThemeChanger";
import Profile from "./components/Page/Profile";

import Dashboard from "./components/Page/Dashboard";
import AddCommodity from "./components/Page/AddCommodity";
import CommodityList from "./components/Page/CommodityList";
import AddCommodityName from "./components/Page/AddCommodityType";
import CommodityTypeList from "./components/Page/CommodityTypeList";
import SubAdminProfile from "./components/subAdmin/SubAdminProfile";
import MyProfile from "./components/superAdmin/MyProfile";
import AllFeatures from "./components/subAdmin/AllFeatures";
import ForgetPassword from "./components/ForgetPassword";
import UserManagement from "./components/Page/userManagement/UserManagement";
import AllBidding from "./components/Page/biddibg/AllBidding";
import BiddingList from "./components/Page/biddibg/BiddingList";
import Banner from "./components/Page/banner/Banner";
import UserAnalytics from "./components/Page/analytics/UserAnalytics";
import InviteSubAdmin from "./components/InviteSubAdmin";
import AcceptInvite from "./components/AcceptInvite";
import Auction from "./components/Page/auction/Auction";
import ListedAuction from "./components/Page/auction/ListedAuction";
import ApprovalAuction from "./components/Page/auction/ApprovalAuction";
import RejectedAuction from "./components/Page/auction/RejectedAuction";
import HighestBids from "./components/Page/biddibg/HighestBids";
import ShowBanner from "./components/Page/banner/ShowBanner";

function App() {
  const [theme, setTheme] = useState("light");
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };

  const handleLogin = (token) => {
    localStorage.setItem("authToken", token);
    setIsLoggedIn(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Router>
      <div
        className={`min-h-screen ${
          theme === "dark" ? "bg-gray-900 text-white" : "bg-white text-black"
        }`}
      >
        <ThemeChanger theme={theme} toggleTheme={toggleTheme} />

        {isLoggedIn ? (
          <>
            {/* <Navbar />
            <div className="flex">
              <Sidebar /> */}
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/allFeatures" element={<AllFeatures />} />
              <Route path="/addCommodity" element={<AddCommodity />} />
              <Route path="/commodityList" element={<CommodityList />} />
              <Route path="/showBanner" element={<ShowBanner />} />
              <Route path="/addCommodityName" element={<AddCommodityName />} />
              <Route
                path="/commodityTypeList"
                element={<CommodityTypeList />}
              />
              <Route path="/subAdmin" element={<SubAdminProfile />} />
              <Route path="/myProfile" element={<MyProfile />} />
              <Route path="/userManagement" element={<UserManagement />} />
              <Route path="/allBidding" element={<AllBidding />} />
              <Route path="/highestBidding" element={<HighestBids />} />
              <Route path="/biddingList" element={<BiddingList />} />
              <Route path="/userAnalytics" element={<UserAnalytics />} />
              <Route path="/inviteSubAdmin" element={<InviteSubAdmin />} />
              <Route path="/auction" element={<Auction />} />
              <Route path="/listedAuction" element={<ListedAuction />} />
              <Route path="/approvalAuction" element={<ApprovalAuction />} />
              <Route path="/rejectedAuction" element={<RejectedAuction />} />
              <Route path="/acceptInvite" element={<AcceptInvite />} />
              <Route path="/banner" element={<Banner />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />

            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
