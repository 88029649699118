import React, { useState } from 'react';
import axios from 'axios';
import Template from './Page/Template';
import { baseUrl } from '../BaseUrl';

const InviteSubAdmin = () => {
  const [email, setEmail] = useState('');
  const [features, setFeatures] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleFeatureChange = (feature) => {
    setFeatures((prev) =>
      prev.includes(feature)
        ? prev.filter((item) => item !== feature)
        : [...prev, feature]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
  
    const token = localStorage.getItem('authToken');
  
    if (!token) {
      setError('User not authenticated. Please log in.');
      return;
    }
  
    const data = {
      email,
      features,
    };
  
    try {
      const response = await axios.post(
        `${baseUrl}/admin/sendInvitationToSubAdmin`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200 || response.status === 201) {
        setSuccess('Sub-admin invited successfully!');
        setEmail('');
        setFeatures([]);
      }
    } catch (err) {
      console.error('API error:', err.response);
      setError(
        err.response?.data?.message || 'Failed to invite sub-admin'
      );
    }
  };
  

  return (
    <Template>
    <div className='bg-green-50 h-screen flex justify-center items-center'>
  <div className="max-w-md w-full mx-auto p-6 bg-white border rounded-lg shadow-md">
    <h2 className="text-2xl font-bold mb-6 text-center">Invite Sub-Admin</h2>
    <form onSubmit={handleSubmit} className=''>
      <div className="mb-6">
        <label className="block mb-2 text-gray-700">Email :</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 border rounded-xl focus:ring-2 focus:ring-green-400"
          required
        />
      </div>

      <div className="mb-6">
        <label className="block mb-2 text-gray-700">Features</label>
        <div className="space-y-2">
          {['Market Place', 'Mandi Price', 'User Management'].map(
            (feature) => (
              <label key={feature} className="flex items-center">
                <input
                  type="checkbox"
                  checked={features.includes(feature)}
                  onChange={() => handleFeatureChange(feature)}
                  className="mr-2"
                />
                {feature}
              </label>
            )
          )}
        </div>
      </div>

      {error && (
        <p className="text-red-500 mb-4 text-center">{error}</p>
      )}
      {success && (
        <p className="text-green-500 mb-4 text-center">{success}</p>
      )}

      <button
        type="submit"
        className="w-full bg-green-600 text-white p-3 rounded hover:bg-green-700"
      >
        Invite
      </button>
    </form>
  </div>
</div>

    </Template>
  );
};

export default InviteSubAdmin;
