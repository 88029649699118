import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import { FaEllipsisV } from 'react-icons/fa';
import axios from 'axios';
 
import Template from '../Template';
import { baseUrl } from '../../../BaseUrl';

const UserManagement = () => {
  const { customer_id } = useParams();  

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);  

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        alert('You need to be logged in to view this page.');
        window.location.href = '/login';
        return;
      }

      try {
        const response = await axios.get(`${baseUrl}/admin/getAllCustomerProfiles`, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('API response:', response.data);

        if (response.data && Array.isArray(response.data.data)) {
          setUsers(response.data.data);
        } else {
          console.error('Unexpected response format:', response.data);
          setUsers([]);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        alert('An error occurred while fetching user data.');
        setUsers([]);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleBlockUser = async () => {
    if (!selectedUserId) return;

    const token = localStorage.getItem('authToken');
    try {
      const response = await axios.post(
        `${baseUrl}/admin/blockCustomer/${customer_id}`,
        {},
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(response.data.message);
      setUsers(prevUsers =>
        prevUsers.filter(user => user.customer_id !== selectedUserId)
      );
      setShowPopup(false);
    } catch (error) {
      console.error('Error blocking user:', error);

      if (error.response) {
        console.error('API Error Response:', error.response.data);
        alert(`Error: ${error.response.data.message || 'An error occurred while blocking the user.'}`);
      } else if (error.request) {
        console.error('API Request Error:', error.request);
        alert('No response from server. Please try again later.');
      } else {
        console.error('Unexpected Error:', error.message);
        alert('An unexpected error occurred.');
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Template>
      <div className="overflow-x-auto  bg-green-50  p-6 h-screen">
        <div className="flex items-center justify-center p-4  py-4 sm:py-8">
          <div className="w-full max-w-xl md:max-w-3xl ">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="overflow-x-auto">
                <table className="w-full min-w-max table-auto border-collapse border border-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="py-4 px-6 text-left">ID</th>
                      <th className="py-4 px-6 text-left">Name</th>
                      <th className="py-4 px-6 text-left">Email</th>
                      <th className="py-4 px-6 text-left">Phone</th>
                      <th className="py-4 px-6 text-left">City</th>
                      <th className="py-4 px-6 text-left">State</th>
                      <th className="py-4 px-6 text-left">Company</th>
                      <th className="py-4 px-6 text-left">Role</th>
                      <th className="py-4 px-6 text-left">Preferred Products</th>
                      <th className="py-4 px-6 text-left">Status</th>
                      <th className="py-4 px-6 text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700 text-sm divide-y divide-gray-200">
                    {Array.isArray(users) && users.length > 0 ? (
                      users.map(user => (
                        <tr key={user.customer_id} className="hover:bg-gray-50 transition duration-200">
                          <td className="py-4 px-6">{user.customer_id}</td>
                          <td className="py-4 px-6 flex items-center">
                            <img 
                              src={user.profileImage || 'https://via.placeholder.com/40'} 
                              alt="profile" 
                              className="w-10 h-10 rounded-full border border-gray-300 mr-4" 
                            />
                            <span>{user.first_name} {user.last_name}</span>
                          </td>
                          <td className="py-4 px-6">{user.email}</td>
                          <td className="py-4 px-6">{user.phoneNumber}</td>
                          <td className="py-4 px-6">{user.city}</td>
                          <td className="py-4 px-6">{user.state}</td>
                          <td className="py-4 px-6">{user.company_name}</td>
                          <td className="py-4 px-6">
                            {user.role && user.role.map(r => (
                              <span key={r} className="mr-2">{r}</span>
                            ))}
                          </td>
                          <td className="py-4 px-6">
                            {user.preferredProducts?.join(', ') || 'N/A'}
                          </td>
                          <td className="py-4 px-6">
                            {user.isActive ? 'Active' : 'Inactive'}
                          </td>
                          <td className="py-4 px-6 text-center">
                            <button 
                              className="text-gray-500 hover:text-gray-700"
                              onClick={() => {
                                setSelectedUserId(user.customer_id);
                                setShowPopup(true);
                              }}
                            >
                              <FaEllipsisV size={18} />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="11" className="text-center py-4">No users found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Popup Modal to Confirm Block User */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold">Block User</h3>
            <p>Are you sure you want to block this user?</p>
            <div className="mt-4 flex justify-end">
              <button 
                className="mr-4 px-4 py-2 bg-gray-500 text-white rounded" 
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
              <button 
                className="px-4 py-2 bg-red-500 text-white rounded"
                onClick={handleBlockUser}
              >
                Block User
              </button>
            </div>
          </div>
        </div>
      )}
    </Template>
  );
};

export default UserManagement;
