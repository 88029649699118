import React, { useState } from "react";
import axios from "axios";  
import { baseUrl } from "../../../BaseUrl";

const ApprovalRejected = ({ customer_auction_id }) => {
  const [isApproval, setIsApproval] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleApprovalClick = () => {
    setIsApproval(true);
    setIsRejected(false);
  };

  const handleRejectedClick = () => {
    setIsRejected(true);
    setIsApproval(false);
  };

  const closePopup = () => {
    setIsApproval(false);
    setIsRejected(false);
    setInputValue("");
  };

  const handleSubmit = async () => {
    const requestData = {
      isApproved: isApproval,
      isRejected: isRejected,
      approvalOrRejectionReason: inputValue,
    };
  
    const token = localStorage.getItem("authToken"); 
  
    try {
      setLoading(true);  
  
      const response = await axios.put(
        `${baseUrl}/admin/approveOrRejectAuctionByAdmin/${customer_auction_id}`,
        requestData,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420", 
            Authorization: `Bearer ${token}`, 
          },
        }
      );
  
      alert(response.data.message);  
    } catch (error) {
      console.error("Error submitting data:", error);  
      alert("Failed to submit. Please try again.");  
    } finally {
      setLoading(false);  
      closePopup(); 
    }
  };
  

  return (
    <div>
      <div className="flex p-2 gap-2">
        <button
          className="px-4 py-2 bg-green-500 text-white rounded"
          onClick={handleApprovalClick}
        >
          Approve
        </button>
        <button
          className="px-4 py-2 bg-red-600 text-white rounded"
          onClick={handleRejectedClick}
        >
          Rejected
        </button>
      </div>

      {/* Popup */}
      {(isApproval || isRejected) && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-semibold mb-4">
              {isApproval ? "Approval Reason" : "Rejection Reason"}
            </h2>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={`Enter your ${isApproval ? "approval" : "rejection"} reason`}
              className="w-full px-4 py-2 border rounded mb-4"
            />
            <div className="flex gap-4">
              <button
                onClick={handleSubmit}
                className={`px-4 py-2 text-white rounded ${
                  loading ? "bg-gray-500 cursor-not-allowed" : "bg-green-500"
                }`}
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
              <button
                onClick={closePopup}
                className="px-4 py-2 bg-red-500 text-white rounded"
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApprovalRejected;
