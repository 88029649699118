import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";

const UpdateBanner = ({ banner_id, fetchBanners}) => {
  const token = localStorage.getItem("authToken");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

 const handleUpdate = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (image) {
      formData.append("banner_image", image);
    }
  try {
    

    const response = await axios.put(
      `${baseUrl}/admin/updateBanner/${banner_id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      }
    );

    if (response.status === 200 || response.status === 201) {
        
      alert("banners are fetch sucessfully")
    }

    setIsPopupOpen(false); 
    fetchBanners();  
  } catch (error) {
    console.error("Error updating banner:", error);

    
  }
};


  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${baseUrl}/admin/deleteBanner/${banner_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      alert("Banner deleted successfully!");
      console.log(response.data);
      fetchBanners();  
    } catch (error) {
      console.error("Error deleting banner:", error);
      alert("Failed to delete the banner.");
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          setIsPopupOpen(true);
        }}
        className="px-4 py-2 bg-green-500 text-white rounded"
      >
        Update
      </button>
      <button
        onClick={handleDelete}
        className="px-4 py-2 bg-red-600 text-white rounded ml-4"
      >
        Delete
      </button>

      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-bold mb-4">Update Banner</h2>
            <label className="block mb-2 text-sm font-medium">
              Title
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
              />
            </label>
            <label className="block mb-2 text-sm font-medium">
              Description
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
              ></textarea>
            </label>
            <label className="block mb-4 text-sm font-medium">
              Upload Image
              <input
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                className="w-full p-2 border border-gray-300 rounded mt-1"
              />
            </label>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsPopupOpen(false)}
                className="px-4 py-2 bg-gray-300 text-black rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdate()}
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateBanner;
