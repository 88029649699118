import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../BaseUrl";
import Template from "../Template";
 

const ApprovalAuction = ( ) => {
  const [auctionData, setAuctionData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAuctionId,setSelectedAuctionId]=useState(-1)
  const [ , setTimeSlot] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchApprovedAuctions = async () => {
      try {
        const apiUrl = `${baseUrl}/admin/getAllApprovedAuctions`;
        const response = await axios.get(apiUrl, {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        });

        setAuctionData(response.data.data);
        
      } catch (error) {
        console.error(
          "Error fetching approved auctions:",
          error.response || error
        );
      }
    };

    if (token) {
      fetchApprovedAuctions();
    }
  }, [token]);

  const handleListNow = ( ) => {
    if (!token) {
      alert("No token found. Please login.");
      return;
    }

    setIsModalOpen(true);
  };

  const handleSubmitTimeSlot = async (customer_auction_id, date, time) => {
  
    const startTime = new Date(`${date}T${time}:00Z`);
    const endTime = new Date(startTime);
    endTime.setHours(startTime.getHours() + 1); 
    const timeSlotData = {
      customer_auction_id: customer_auction_id,
      time_slot_start: startTime.toISOString(), 
      time_slot_end: endTime.toISOString(),
    };
  
    try {
      const response = await axios.post(
        `${baseUrl}/admin/listAuction/${customer_auction_id}`,
        timeSlotData,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Time Slot Set:", response.data);
      alert("Time slot has been set successfully.");
      handleCloseModal();
    } catch (error) {
      console.error("Error setting time slot:", error);
      alert("Failed to set time slot.");
    }
  };
  

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setTimeSlot("");
  };

  return (
    <Template>
      <div className="p-6 bg-green-50">
        <h1 className="text-2xl font-semibold mb-4">Approved Auctions</h1>
        <div className="overflow-x-auto">
          {auctionData.length > 0 ? (
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-100 border-b">
                  <th className="py-2 px-4 border whitespace-nowrap">
                    Auction ID
                  </th>
                  <th className="py-2 px-4 border whitespace-nowrap">
                    Commodity Name
                  </th>
                  <th className="py-2 px-4 border whitespace-nowrap">
                    Quantity
                  </th>
                  <th className="py-2 px-4 border whitespace-nowrap">
                    Base Price
                  </th>
                  <th className="py-2 px-4 border whitespace-nowrap">Grade</th>
                  <th className="py-2 px-4 border whitespace-nowrap">
                    Quality Type
                  </th>
                  <th className="py-2 px-4 border whitespace-nowrap">Images</th>
                  <th className="py-2 px-4 border whitespace-nowrap">
                    Approved At
                  </th>
                  <th className="py-2 px-4 border whitespace-nowrap">
                    List Now
                  </th>
                </tr>
              </thead>
              <tbody>
                {auctionData.map((auction) => (
                  <tr key={auction.customer_auction_id} className="border-b">
                    <td className="py-2 px-4 border">
                      {auction.customer_auction_id}
                    </td>
                    <td className="py-2 px-4 border">
                      {auction.commodity_name}
                    </td>
                    <td className="py-2 px-4 border">
                      {auction.quantity} {auction.quantity_unit}
                    </td>
                    <td className="py-2 px-4 border">
                      {auction.currency} {auction.base_price} /{" "}
                      {auction.base_price_unit}
                    </td>
                    <td className="py-2 px-4 border">{auction.grade}</td>
                    <td className="py-2 px-4 border">{auction.quality_type}</td>
                    <td className="py-2 px-4 border">
                      <div className="flex space-x-2">
                        {auction.images.map((img, index) => (
                          <img
                            key={index}
                            src={img}
                            alt={`Auction ${index}`}
                            className="w-16 h-16 object-cover rounded"
                          />
                        ))}
                      </div>
                    </td>
                    <td className="py-2 px-4 border">
                      {new Date(
                        auction.approvedOrRejectedAt
                      ).toLocaleDateString()}
                    </td>
                    <td className="p-3">
                      <button
                        onClick={() =>
                         
                       {   setSelectedAuctionId(auction['customer_auction_id']);
                          handleListNow(auction.customer_auction_id)}
                        }
                        className="bg-green-500 text-sm text-white px-4 py-2 rounded hover:bg-green-600 ml-2"
                      >
                        List Auction
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center text-lg text-gray-600">Network error</p>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md w-1/3">
            <h2 className="text-xl mb-4">Set Time Slot for Auction</h2>

            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="border p-2 w-full mb-4"
            />

           
            <input
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              className="border p-2 w-full mb-4"
            />

            <div className="flex justify-end space-x-2">
              <button
                onClick={handleCloseModal}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Close
              </button>
              <button
                onClick={() =>
                  handleSubmitTimeSlot(
                    selectedAuctionId,
                    date,
                    time
                  )
                }
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </Template>
  );
};

export default ApprovalAuction;
